import React, { useState, useEffect } from "react";
import axios from 'axios';
import CloseSidebar from "../../Main/CloseSidebar";
import Select from "react-select";
import toast, { Toaster } from 'react-hot-toast';

function Services(props) {
    CloseSidebar();

    const API_SCRIPT = process.env.REACT_APP_API_SCRIPT;
    const [isLoaded, setIsLoaded] = useState(false);

    const [service, setService] = useState();
    const [price, setPrice] = useState();
    const [max, setServiceMax] = useState();
    const [select, setSelect] = useState();
    const [count, setCount] = useState();
    const [items, setItems] = useState([]);
    const [isPayInfo, setPayInfo] = useState(false);

    const config = {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    };

    useEffect(() => {
        setIsLoaded(true);

        const data = {
            path: 'client/pay/services',
            school_id: props.auth.school_id,
            client_id: props.auth.client_id
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                const prices = response.prices;
                setItems(prices);
                setPayInfo(response)

                if (items) {
                    const first = response.prices[0];
                    setService(first.id)
                    setPrice(first.price)
                    setServiceMax(first.max)
                    setCount(1);
                    setSelect(0);
                }

                setIsLoaded(false)
            } else {
                toast.error('Ошибка получения списка дополнительных услуг', {
                    duration: 5000,
                    position: 'bottom-center'
                });
            }
        })
    }, [])

    if (isPayInfo.method === false || isPayInfo.status === 'fail') {
        return (
            <React.Fragment>
                <div className="error-alert pt-4">
                    <div className="bg-white text-center ml-4 mr-4 pt-4 pb-4">
                        <img src="https://client.pravamobil.ru/dist/img/alert-error.png" alt="" />
                        <div className="text-bold mt-4">Оплата не доступна!<br />Обратитесь в свою автошколу!</div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    let options = [];

    let serviceList = items.map((item, index) => {
        options.push({value: index, label: item.title, index: index})

        return (
            <option key={ item.id } value={ index }>{ item.title }</option>
        )
    })

    const onSelectHandler = item => {
        item = item.index;
        setService(items[item].id);
        setPrice(items[item].price)
        setServiceMax(items[item].max)
        setSelect(item)
    }

    const onSubmitForm = event => {
        event.preventDefault()

        setIsLoaded(true);

        const data = {
            path: 'pay/prices/create',
            school_id: props.auth.school_id,
            client_id: props.auth.client_id,
            price_id: service,
            count: count
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                window.location.replace(response.url)
            }
        })
    }

    return (
        <React.Fragment>
            { isLoaded === false ? (
                <div className="services-page">
                    <div className="services-page-info-header text-center p-4">
                        <div className="services-page-info-header-text">Дополнительные услуги</div>
                    </div>
                    <div className="services-page-list">
                        <form onSubmit={ onSubmitForm }>
                            <div className="services-page-custom-select">
                                <Select
                                    value={options[select]}
                                    options={options}
                                    onChange={onSelectHandler}
                                    classNamePrefix="service__select"
                                    isSearchable={false}
                                />
                            </div>
                            <div className="services-page-price-info d-flex justify-content-between">
                                <div>Цена за единицу</div>
                                <div className="services-price text-center w-50">{ price } руб.</div>
                            </div>
                            <div className="services-page-price-count d-flex justify-content-between align-items-center">
                                <div>Количество</div>
                                { max > 0 ? (
                                    <input type="number" className="text-center w-50" onChange={e => setCount(e.target.value)} max={ max } required />
                                ) : (
                                    <input type="number" className="text-center w-50" onChange={e => setCount(e.target.value)} required />
                                )}
                            </div>
                            <div className="services-page-total-block d-flex justify-content-between">
                                <div>Сумма к оплате:</div>
                                <div className="services-page-total-amount">{ price * count } руб.</div>
                            </div>
                            <button type="submit" className="services-page-button w-100">Оплатить</button>
                        </form>
                    </div>
                </div>
            ) : (
                <div className="content">
                    <div className="loader">
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                    </div>
                </div>
            ) }
            <Toaster />
        </React.Fragment>
    )
}

export default Services;
