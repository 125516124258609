import React, { useState, useEffect } from "react";
import axios from 'axios';
import CloseSidebar from "../../Main/CloseSidebar";

function Early(props) {
    CloseSidebar();

    const API_SCRIPT = process.env.REACT_APP_API_SCRIPT;
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPayInfo, setPayInfo] = useState([]);

    const config = {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    };

    useEffect(() => {
        setIsLoaded(true);

        const data = {
            path: 'client/pay/info',
            school_id: props.auth.school_id,
            client_id: props.auth.client_id
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                setPayInfo(response);
                setIsLoaded(false)
            }
        })
    }, [])

    if (isPayInfo.method === false || isPayInfo.status === 'fail') {
        return (
            <React.Fragment>
                <div className="error-alert pt-4">
                    <div className="bg-white text-center ml-4 mr-4 pt-4 pb-4">
                        <img src="https://client.pravamobil.ru/dist/img/alert-error.png" alt="" />
                        <div className="text-bold mt-4">Оплата не доступна!<br />Обратитесь в свою автошколу!</div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const moneyFormat = amount => {
        let format = parseInt(amount).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ");
        format = format.split('.');
        return format.shift()
    }

    const earlyPayClick = (event) => {
        event.preventDefault()
        setIsLoaded(true);

        const data = {
            path: 'pay/create',
            school_id: props.auth.school_id,
            client_id: props.auth.client_id,
            first: false,
            next: false
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                window.location.replace(response.url)
            }
        })
    }

    return (
        isLoaded === false && isPayInfo ? (
            <div className={'pay-page' + (isPayInfo.expired ? ' is-debt' : '')}>
                { isPayInfo.pay < isPayInfo.amount ? (
                    <div className="pay-page-info-header text-center p-4">
                        <div className="pay-page-info-header-date-text">Досрочная оплата</div>
                        <div className="pay-page-info-header-amount">{ moneyFormat(isPayInfo.debt) } руб.</div>
                    </div>
                ) : ( '' ) }
                <div className="pay-page-content">
                    <button onClick={ earlyPayClick } className="services-page-button w-100">Оплатить</button>
                </div>
            </div>
        ) : (
            <div className="content">
                <div className="loader">
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                </div>
            </div>
        )
    )
}

export default Early;